<template>
  <div id="app">
      <el-header style="padding:0px;">
        <!-- background-color="#285482"  -->
        <el-menu
          :default-active="activeIndex" 
          class="el-menu-demo" 
          mode="horizontal" 
          @select="handleSelect" 
          background-color="#285482"
          text-color="#fff"
          active-text-color="#ffd04b"
          style="position:relative;"
          >
          <el-menu-item v-for="(item,index) in navList" :key="index" :index="index +''">{{item}}</el-menu-item>
		  <el-menu-item style="position: absolute;right:230px;">
           <el-tooltip class="item" effect="dark" content="待办事项提醒" placement="top-start">
              <div :class="{backlogBox:backlog_status}" style="position: relative;" @click="handleBacklog()">
                  <i style="color:#FFFFFF;" class="el-icon-warning-outline"></i>
                  <span style="position:absolute;top:-15px;left:18px;font-size: 10px;" v-if="adminInfo.backlog_num>0">{{adminInfo.backlog_num}}</span>  
              </div>
            </el-tooltip>
          </el-menu-item>
              <el-submenu index="3" style="position:absolute;right:35px;">
                <template slot="title">
                    <span>欢迎您：</span>
                    <span style="padding-right:10px;">{{adminInfo.name}}</span>
                    <img class="u-icon" :src="adminInfo.icon">
                </template>
              
                <el-menu-item index="2-1" @click="myInfo">我的资料</el-menu-item>
                <el-menu-item index="2-2" @click="logout()">退出</el-menu-item>
              </el-submenu>
              
        </el-menu>
      </el-header>
      <el-container style="height:100%">
        <el-aside width="300px">
           <!-- default-active="$route.path" -->
          <el-menu
            default-active="$route.path"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            :default-openeds="openeds"
            ref="elMenu"
            >
            <div v-for="(item,index) in sidebarList" :key="index">
                <div v-if="item.children">
                    <el-submenu :index="index +''">
                        <template slot="title">
                            <i :class="item.icon" style="font-size:16px;"></i>
                            <span style="font-weight:600;">{{item.name}}</span>
                        </template>
                        <el-row class="submenu">
                            <el-col :span="12" v-for="(item2,index2) in item.children" :key="index2" >
                                <el-menu-item-group >
                                   <!-- old -->
                                  <!-- <el-menu-item 
                                      :class="[path==item2.url?'is-active':'']"  
                                      style="height:28px;line-height:28px;" 
                                      :index="index2+''" 
                                      @click="handleMenuSelect(item2.url,item2.name)">
                                      {{item2.name}}  <label>{{index2}}</label>
                                  </el-menu-item> -->
                                  <el-menu-item 
                                      :class="[path==item2.url?'is-active':'']"  
                                      style="height:25px;line-height:25px;font-size: 12px;" 
                                      :index="item2.id + ''"
                                      @click="handleMenuSelect(item2.url,item2.name)">
                                      {{item2.name}}
                                  </el-menu-item>
                                </el-menu-item-group>
                            </el-col>
                        <!-- <el-menu-item-group v-for="(item2,index2) in item.children" :key="index2"> 
                            <el-menu-item :index="index2+''" @click="handleMenuSelect(item2.url)">{{item2.name}}</el-menu-item>                                                        
                        </el-menu-item-group> -->
                        </el-row>
                     </el-submenu>
                </div>
                <div v-else>
                  <!-- :class="[path==item.url?'is-active':'']" -->
                     <el-menu-item :index="index +''"  @click="handleMenuSelect(item.url,item.name)" :class="[path==item.url?'is-active':'']">
                        <div v-if="!item.children">
                            <i :class="item.icon"></i>
                            <span slot="title">{{item.name}}</span>
                        </div>
                    </el-menu-item>
                </div>
            </div>
          </el-menu>
        </el-aside>
        <el-main class="main-content">
          <meta name="referrer" content="never">
          <router-view  @handleBeforeUpload="handleBeforeUploadImg"   @handleBeforeUploadVideo="handleBeforeUploadVideo" @handleBeforeUploadFile="handleBeforeUploadFile" />
          <!-- <router-view @handleBeforeUploadVideo="handleBeforeUploadVideo" /> -->
           <!-- <router-view v-if="isRouterAlive" /> -->
        </el-main>
      </el-container> 
	  
	  <AddVue ref="addChildDialog"></AddVue>
  </div>
</template>
<script>
import { ifError } from 'assert';
import router from '../router';
import store from '../store'
import AddVue from "./Admin/Add.vue"
export default{
   inject:['reload'],
    store,
	components:{
		AddVue
	},
    // provide(){
    //     return {
    //         reload:this.reload
    //     }
    // },
  data(){
    return{
      path:'',
      activeIndex:'0',
      navList:[],
      sidebarList:[],
      navIndex:0,   
      // isRouterAlive:true,
      adminInfo:[], 
      openeds:[],
      backlog_status:false,
	  fileList:[],
    }
  },
   created(){
       this.getData();
       this.getAdminData();
      //  this.defaultActive =  this.$route.path
       
      //  this.getCompanyList()
   },
  mounted(){
    this.path  = this.$route.path
    // console.log(this.$route.path)
  },
  watch:{
    $route(to,form){
      this.path =  to.path
    }
  },
  computed:{

  },
  methods:{
      //上传图片文件之前的钩子，参数为上传的文件
      handleBeforeUploadImg(file){
		  console.log(file.type)
          const isJPG = file.type === "image/jpeg";
          const isPNG = file.type === "image/png";
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isJPG && !isPNG) {
              this.$message.error("上传图片只能是jpg格式、png格式");
          } 
          if (!isLt2M) {
              this.$message.error("上传头像大小不超过2MB");
          }
          return (isJPG || isPNG) && isLt2M;
      },  

      //上传视频  10M内
      handleBeforeUploadVideo(file){
          const isLt10M = file.size / 1024 / 1024 < 10;
          if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(file.type) == -1) {
            this.$message.error('请上传正确的视频格式');
            return false;
          }
          if(!isLt10M){
              this.$message.error("上传视频大小不能超过10MB哦!");
              return false;
          }
      },

      //上传文件
      handleBeforeUploadFile(file,fileList,excelStatus=false,callback){
        const isLt20M = file.size / 1024 / 1024 < 20;
        if(!isLt20M){
             this.$message.error("上传文件大小不能超过20MB哦!");
              return false;
        }
        let fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
        if(excelStatus == true){    //仅表格，一般用于导入
            if(fileType != 'xlsx' && fileType != 'xls'){
                // this.fileList = []
                this.$message.error('只能上传xls、xlsx格式文件')
                return false
            }
        }else{
             if(fileType != 'xlsx' && fileType != 'xls' && fileType != 'txt' && fileType != 'doc' && fileType != 'docx'){
                // this.fileList = []
                this.$message.error('只能上传xls、xlsx、doc、docx、txt格式文件')
                return false
            }
        }
		if(fileList.length > 0){
			let index  = fileList.length-1
			let list = []
			list.push(fileList[index])
			this.fileList =  list 
			callback(list)
			// return list
		}
      },

      // reload(){
      //     this.isRouterAlive = false
      //     this.$nextTick(function(){
      //         this.isRouterAlive =  true
      //     })
      // },
      getAdminData(){
        this.$api.get('admin/getCurrAccount',null,res=>{
            if(res.code == 200){
              // res.data.icon =  this.$domain+res.data.icon
              localStorage.setItem('role_id',res.data.role_id)
              this.adminInfo = res.data
              this.backlog_status = res.data.backlog_num>0?true:false
              if(res.data.role_id == 1){  //当前用户未超管 则获取企业列表 
                this.getCompanyList()
              }
            }
        })
      },
	  
      getData(){    //头部导航和侧边栏数据
        this.activeIndex = sessionStorage['topNavIndex']??'0'
        // console.log(this.activeIndex)
        this.$api.get('menu/getMenuList',null,res=>{
            if(res.code == 200){
              let i=0;
              let list = res.data
              store.commit('menuList',list)
              for(i; i<list.length;i++){
                  this.navList.push(list[i]['name'])
                  if(list[this.activeIndex]['children'] !== 'null' && list[this.activeIndex]['children'] !== 'undefined'){
                    this.sidebarList =  list[this.activeIndex]['children']
                  }
                  // this.sidebarList =  list[this.navIndex]['children']
              }
              // console.log(this.sidebarList)
              // console.log(this.navList)
              let j=0;
            
              if(this.sidebarList !== undefined && this.sidebarList.length > 0){
                for(j;j<this.sidebarList.length;j++){ 
                  this.openeds.push(""+j+"")      //默认展开所有
                }
              }
            }
        })
     },
  

    getCompanyList(){ //获取企业列表
      let list = localStorage.getItem('companyList')
      if(!list || typeof list === 'undefined'){ 
          this.$api.post('Company/getCompanyList',null,res=>{
				if(res.code == 200){
					localStorage.setItem('companyList',JSON.stringify(res.data))
				}
			})
       }
    },


     handleSelect(key,keyPath){         //头部导航切换
        //  console.log('Listening')
        //  console.log( this.activeIndex)
         let list  = store.state.menuList
		this.sidebarList  = list[key]['children']
		//  store.commit('setTopNavIndex',key)
		sessionStorage['topNavIndex'] = key
		
     },
     handleOpen(key, keyPath){
        // console.log('open:'+key)
     },
     handleClose(key, keyPath){
        //  console.log('close:'+key)
     },
     handleMenuSelect(path,name){
        // console.log('调整')
         //跳转菜单
         if(path && path !== this.$route.path){
            this.$router.push({path:path,query:{main_title:name}})
         }
     },
     logout(){
         this.$router.push('/')
     },
     //待办事项
    handleBacklog(){ 
      this.reload();
      sessionStorage['topNavIndex'] = 0
      this.$router.push({path:'/Backlog/index',query:{main_title:'待办事项'}})
    
     },
     
    //我的资料跳转
    myInfo(){
		if(this.$route.path == '/admin/index'){
			this.$router.push({query:{main_title:'账号管理',dialogAddVisible:true}})
			this.reload() 
		}else{
			// this.reload();
			sessionStorage['topNavIndex'] = 1 
			this.$router.push({path:'/admin/index',query:{main_title:'账号管理',dialogAddVisible:true}})
		}
    },
   
  }
}
</script>

<style lang="scss">
body{
  margin:0;
}
.main-title{
    margin-top: -5px; 
    font-size:16px; 
    font-weight:600;
}
#app{
  padding: 0;
  margin: 0;
  .backlogBox{
      animation: fade 600ms infinite;    
     -webkit-animation: fade 600ms infinite;
     .el-icon-warning-outline{
       color: #00cc00 !important;
     }
     color: #00cc00;
  }
  @keyframes fade {
    from {        
      opacity: 1.0;    
    }
    50% {        
      opacity: 0.4;    
    }
    to {        
      opacity: 1.0;    
    }
}

@-webkit-keyframes fade {    
    from {        
      opacity: 1.0;    
    }
    50% {        
      opacity: 0.4;    
    }
    to {        
      opacity: 1.0;    
    }
}
  .el-submenu .el-menu-item{
    min-width: 0px !important;
    
  }
  .el-header{
    //  .el-menu--horizontal>.el-menu-item.is-active{
    //         color: white;
    //     } 
    //     .el-menu--horizontal > .el-submenu .el-submenu__title:hover{
    //     background:transparent;
    //     }
      .el-menu{
        background-color: #285482;
        // .el-menu-item{
        //     color: white;
        //     // color: #2f2f2f;
        // }
        // .el-submenu .el-submenu__title{
        //     color: white;
        //     //  color: #2f2f2f;
        // }
        // .el-menu--horizontal>.el-menu-item.is-active{
        //   color: black !important;
        // }

  
        
      }
  }

  .el-aside{
     width: 250px !important;
      ul{
          height: 100%;
      }
  }
  .u-icon{
      width: 35px;
      height: 35px;
      border: 1px solid #e6e6e6;
      border-radius: 18px;
  }
 
}
html,body,#app{
  height: 100%;
}

.header-nav{
  line-height: 60px;
}
.el-pagination{
  text-align: right;
  margin-top: 20px;
}
</style>
