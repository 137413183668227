<template>
<div>
	<!-- 添加管理员 -->
	<el-dialog title="添加管理员" :visible.sync="dialogAddVisible" class="admin-dialog"  :close-on-click-modal="false" >
	    <el-form ref='form' :model="form" prop="" label-width="150px">
	          <el-form-item label="关联企业" prop="corpid" v-if="roleId == 1">
	             <el-select v-model="form.corpid" placeholder="请选择" @change="handleChangeCorp">
	                <el-option v-for="(item,index) in corpList" :key="index" :label="item.title" :value="item.id"></el-option>
	            </el-select>
	        </el-form-item>
			<el-form-item label="部门" prop="" >
			  <!-- <el-select v-model="form.department" placeholder="请选择">
			       <el-option v-for="(item,index) in deptList" :key="index" :label="item.name" :value="item.id"></el-option>
			   </el-select> -->
			   <el-cascader
			   disabled="true"
			   	v-model="form.dept_id"  
			   	 :props="props" 
			   	:options="deptList" 
			   	  ref="cascader"
			   	></el-cascader>
			</el-form-item>
			
	        <el-form-item label="头像" prop="icon">
	            <el-upload
	                class="avatar-uploader"
	                :action="$uploadUrl"
	                :show-file-list="false"
	                :on-success="handleAvatarSuccess"
	                :before-upload="beforeAvatarUpload" >
	                <img v-if="imageUrl" :src="imageUrl" class="avatar">
	                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
	            </el-upload>  
	        </el-form-item>
	        <el-form-item label="选择角色" prop="role_id">
	             <el-select v-model="form.role_id" placeholder="请选择">
	                <el-option v-for="(item,index) in roleList" :key="index" :label="item.name" :value="item.id"></el-option>
	            </el-select>
	        </el-form-item>
	        <el-form-item label="职员姓名" prop="name">
	            <el-input  v-model="form.name"></el-input>
	        </el-form-item>
	        <el-form-item label="性别" prop="gender">
	            <template>
	                <el-radio v-model="form.gender" :label="0"><img src="/images/nansheng.png"></el-radio>
	                <el-radio v-model="form.gender" :label="1"><img src="/images/nvsheng.png"></el-radio>
	            </template>
	        </el-form-item>
	        <el-form-item label="登录名" prop="nickname">
	            <el-input  v-model="form.nickname"></el-input>
	            <span style="color:#909399;display:block;">如后期需跟成员绑定的话，则该字段需要跟成员账号填写一致才可绑定</span>
	        </el-form-item>
	        <el-form-item label="密码" prop="password">
	            <el-input  v-model="form.password" type="password" show-password></el-input>
	        </el-form-item>
	         <el-form-item label="手机号" prop="mobile">
	            <el-input  v-model="form.mobile"></el-input>
	        </el-form-item>
	          <el-form-item label="职务信息" prop="position">
	            <el-input  v-model="form.position" :disabled="form.create_mode==1?true:false"></el-input>
	        </el-form-item>
	        <el-form-item label="状态" prop="status">
	            <template>
	                <el-radio v-model="form.status" :label="0">离职</el-radio>
	                <el-radio v-model="form.status" :label="1">在职</el-radio>
	            </template>
	        </el-form-item>
	    </el-form>
	    <div slot="footer" class="dialog-footer">
	        <el-button @click="dialogAddVisible = false">取 消</el-button>
	        <el-button type="primary" @click="confirmAdd()">确 定</el-button>
	    </div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			dialogAddVisible:false,
			imageUrl: "",
			form: {
				icon: "",
				role_id: '',
				name: "",
				nickname: "",
				gender: 1,
				password: "",
				mobile: "",
				status: 1,
				position:'',
				dept_id:[]
			},
			props:{
				value:'id',
				label:'name',
			},
			roleList: [],		//角色绑定
			corpList:[],		//企业人员
			deptList:[],		//企业部门
			roleId:localStorage.getItem('role_id'),     //当前登录角色
        }
    },
    created() {
    },
    mounted() {

    },
    methods:{
		showDialog(id=0){
			if (id > 0) {
				this.handleEdit(id)
			} else{
				this.handleAdd()
			}
		},
		handleAvatarSuccess(res, file) {
		    if (res.code == 200) {
		        this.imageUrl = this.$imgDomain  + res.data.url
		        this.form.icon = res.data.url;
		    }
		},
		beforeAvatarUpload(file) {
		     this.$emit('handleBeforeUpload',file)
		},
		handleAdd() {		//添加成员
		    this.imageUrl  =  ''
		    // this.$refs["form"].resetFields();
		    this.$api.get("Admin/add", null, res => {
		        if (res.code == 200) {
		            this.dialogAddVisible = true;
		            this.roleList = res.data.role_list;
					this.corpList  = res.data.corp_list
					this.deptList =  res.data.dept_list
		            // this.corpList = localStorage.getItem('companyList')
					// console.log(this.corpList)
		        }
		    });
		},
		//编辑
		handleEdit(id) {
			this.dialogAddVisible = true;
			// this.$api.get("Role/index", null, res => {
			// 	if (res.code == 200) {
			// 		this.roleList = res.data.data;
			// 	} 
			// }); 
			this.$api.get("Admin/edit", { "id": id }, res => {
				if (res.code == 200) {
					// res.data.status = res.data.status.toString();
					this.form = res.data;
					if(res.data.icon_host_status){
						this.imageUrl = res.data.icon;
					}else{
						 this.imageUrl = this.$imgDomain + res.data.icon;
					}
					this.roleList =  res.data.role_list
					this.corpList =  res.data.corp_list
					this.deptList =  res.data.dept_list
				}
			});
		},
		handleChangeCorp(val){
			this.$api.get("Admin/add", {'coid':val}, res => {
			    if (res.code == 200) {
					this.deptList =  res.data.dept_list
			    }
			});
		},
		confirmAdd() {      //提交表单
		    this.$api.post("Admin/save", this.form, res => {
		        if (res.code == 200) {
		            this.$message.success(res.msg);
		            this.dialogAddVisible = false;
		            this.$refs["form"].resetFields();
		            this.reload();
		        }
		    });
		},
    },
}
</script>
<style scoped>

</style>